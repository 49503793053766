import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Img from "gatsby-image";

const PhotoGuide = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title={"Photo Guide"} description={""}/>

                <>
                    <div className="container-fluid">
                        <div className="container">
                            {/* SECTION: INTRODUCTION*/}
                            <div className="row pt-5">
                                <div className="col mb-3">
                                    <h1 className="text-primary-dark font-weight-800 letter-spacing-n2">Vehicle Photography Guide</h1>
                                    <h3 className="text-primary-dark font-weight-800 letter-spacing-n2">With A Focus On 'Framing'</h3>
                                </div>
                            </div>{/* row ends */}
                            <div className="row mb-5">
                                <div className="col-xs-12 col-lg-6">
                                    <p>These shots are designed to show the majority of the vehicle's exterior - offering potential buyers a 360° view. Try to find a location with plenty of space, ensuring no other vehicles or visual distractions are in shot. Wherever possible  avoid any reflections - particularly on areas of paintwork.</p>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <ul>
                                        <li>Stand back – ensuring the entire vehicle is in shot</li>
                                        <li>Balance spacing around the vehicle - ensuring it is central within the shot</li>
                                        <li>Try to get shots from either side to match as closely as possible - mirrored&nbsp;images work best</li>
                                    </ul>
                                </div>
                            </div>{/* row ends */}
                            <hr />
                            {/* SECTION: EXTERIOR SHOTS*/}
                            <div className="row pt-5">
                                <div className="col mb-1">
                                    <h3 className="text-primary-dark font-weight-800 letter-spacing-n2">Exterior Shots <span className="no-wrap">- With A Focus On 'Framing'</span></h3>
                                </div>
                            </div>{/* row ends */}
                            <div className="row ">
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_0.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front three-quarters view <span>(driver side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_1.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front view <span>(head-on)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_2.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front three-quarters view <span>(passenger side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_3.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Side view <span>(passenger side)</span></p>
                                </div>
                                <div className="d-none d-xl-block col-xl-4 text-center"> {/* align-self-center */}
                                    <Img lassName="img-fluid" fluid={data.polo_4.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Walkaround <span>Video</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_5.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Side view <span>(driver side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_6.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear three-quarters view <span>(passenger side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_7.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear view <span>(head-on)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_8.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear three-quarters view <span>(driver side)</span></p>
                                </div>
                            </div>{/* row ends */}
                            <hr />
                            {/* EXTERIOR DETAIL*/}
                            <div className="row pt-5">
                                <div className="col mb-1">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n2">Exterior Detail <span className="no-wrap">- Wheels</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row">
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_9.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear wheel <span>(driver side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_10.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front wheel <span>(driver side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_11.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front wheel <span>(passenger side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_12.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear wheel <span>(passenger side)</span></p>
                                </div>
                            </div>{/* row ends */}
                            <div className="row mb-5">
                                <div className="col-xs-12 col-lg-6">
                                    <p>Whatever type of wheel your car has, making sure they shine is a must when preparing to sell your car. Not only will they make the car look far more appealing, but dirty wheels can hide all sorts of damage - putting potential buyers off.</p>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <ul>
                                        <li>Centralise the wheel within frame</li>
                                        <li>Stand far enough back to include the whole tyre</li>
                                        <li>If possible, ensure badges are correct way up</li>
                                    </ul>
                                </div>
                            </div>{/* row ends */}
                        </div>{/* container ends */}
                    </div>{/* container-fluid ends */}
                    {/* SPLIT BETWEEN INTERIOR AND EXTERIOR - Starts */}
                    <div className="container-fluid" style={{background: '#f1f1f1'}}>
                        <div className="container pt-5">
                            <div className="row mt-3">
                                <div className="col mb-1">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n1">Interior <span>/</span> Exterior Detail <span className="no-wrap">- Boot &amp; Spare Wheel Well</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_13.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Open boot <span>(head-on)</span></p>
                                    <ul>
                                        <li>Ensure the boot is empty and clean</li>
                                        <li>Try to illustrate the amount of space available</li>
                                        <li>Ensure camera is level and central with car</li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_14.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Spare wheel well <span>(head-on)</span></p>
                                    <ul>
                                        <li>Ensure spare wheel is central within shot</li>
                                        <li>Allow space on all sides to include wheel well</li>
                                    </ul>
                                </div>
                            </div>{/* row ends */}
                        </div>{/*container ends */}
                    </div>{/* container-fluid ends */}
                    {/* SPLIT BETWEEN INTERIOR AND EXTERIOR - Ends */}
                    {/* SECTION: INTERIOR SHOTS*/}
                    <div className="container-fluid">
                        <div className="container">
                            {/* DESKTOP Version*/}
                            <div className="row pt-5 d-none d-xl-block">
                                <div className="col mb-3">
                                    <h3 className="text-primary-dark font-weight-800 letter-spacing-n2">Interior Shots <span>- With A Focus On Detail</span></h3>
                                </div>
                            </div>{/* row ends */}
                            <div className="row mb-5">
                                <div className="col-xs-12 col-lg-6 d-none d-xl-block">
                                    <p>These shots aim to show both the quality and condition of the interior. Ensure carpets have been vacuumed and all surfaces wiped down. Shut all doors and windows appearing in shot. The glove compartment and any other storage should also remain shut. </p>
                                </div>
                                <div className="col-xs-12 col-lg-6 d-none d-xl-block">
                                    <ul>
                                        <li>Crop tight – ensure no external details are in shot</li>
                                        <li>Remove any personal belongings from the car</li>
                                        <li>Try to get shots from either side to match as closely as possible - mirrored&nbsp;images work best</li>
                                    </ul>
                                </div>
                            </div>{/* row ends */}
                            <hr className="d-none d-xl-block" />
                            {/* INTERIOR DETAIL */}
                            <div className="row pt-5 d-none d-xl-block">
                                <div className="col mb-1">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n2">Interior Detail <span>- Front</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row">
                                <div className="col-xl-4 d-none d-xl-block mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_15.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front interior <span>(driver side)</span></p>
                                </div>
                                <div className="col-xl-4 d-none d-xl-block mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_16.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front interior <span>(head-on)</span></p>
                                </div>
                                <div className="col-xl-4 d-none d-xl-block mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_17.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front interior <span>(passenger side)</span></p>
                                </div>
                            </div>
                            <div className="row pt-5 d-none d-xl-block">
                                <div className="col mb-1">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n2">Interior Detail <span>- Front</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row">
                                <div className="col-xl-4 d-none d-xl-block mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_18.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear interior <span>(driver side)</span></p>
                                </div>
                                <div className="col-xl-4 d-none d-xl-block mb-5">
                                <Img lassName="img-fluid" fluid={data.polo_19.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear interior <span>(head-on)</span></p>
                                </div>
                                <div className="col-xl-4 d-none d-xl-block mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_20.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear interior <span>(passenger side)</span></p>
                                </div>
                            </div>{/* row ends */}
                            {/* DESKTOP Version ends */}

                            {/* MOBILE Version */}
                            <div className="row pt-5 d-xl-none">
                                <div className="col mb-3">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n2">Interior Shots <span>- With A Focus On Detail</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row d-xl-none mb-5">
                                <div className="col-xs-12 col-lg-6">
                                    <p>These shots aim to show both the quality and condition of the interior. Ensure carpets have been vacummed and all surface wiped down. Shut all doors and windows appearing in shot. The glove compartment and any other storage should also remain shut. </p>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <ul>
                                        <li>Crop tight – ensure no external details are in shot</li>
                                        <li>Remove any personal belongings from the car</li>
                                        <li>Try to get shots from either side to match as closely as possible - mirrored&nbsp;images work best</li>
                                    </ul>
                                </div>
                            </div>{/* row ends */}
                            <hr className="d-xl-none" />
                            {/* INTERIOR DETAIL */}
                            <div className="row pt-5 d-xl-none">
                                <div className="col mb-1">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n2">Interior Detail <span>- Front &amp; Rear</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row d-xl-none">
                                <div className="col-xs-12 col-lg-6 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_15.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front interior <span>(driver side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 mb-5">
                                    {/* <Img lassName="img-fluid" fluid={data.polo_16.childImageSharp.fluid} alt="" /> */}
                                    <p className="imagedetail">Front interior <span>(head-on)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_17.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Front interior <span>(passenger side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_18.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear interior <span>(driver side)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_19.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear interior <span>(head-on)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_20.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Rear interior <span>(passenger side)</span></p>
                                </div>
                            </div>{/* row ends */}
                            {/* MOBILE Version ends */}


                            <div className="row"> {/* pt-5 */}
                                <div className="col mb-1">
                                    <h4 className="text-primary-dark font-weight-800 letter-spacing-n2">Interior Detail <span>- Dash</span></h4>
                                </div>
                            </div>{/* row ends */}
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_21.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Odometer</p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-4 mb-5">
                                    <Img lassName="img-fluid" fluid={data.polo_22.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Digital Display</p>
                                </div>
                            </div>{/* row ends */}
                            <hr />
                            <div className="row pt-5">
                                <div className="col mb-1">
                                    <h3 className="text-primary-dark font-weight-800 letter-spacing-n2">Documents <span>- Service History</span></h3>
                                </div>
                            </div>{/* row ends */}
                            <div className="row mb-5">
                                <div className="col-xs-12 col-lg-6">
                                    <p>Please provide photos of any service history documentation you have. The more complete the service history provided, the easier the vehicle will be to sell, and the higher the quality of offers received.</p>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <p>Please be aware that the MOT history is not the service history.</p>
                                </div>
                            </div>{/* row ends */}
                            <div className="row pb-5">
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_23.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Service History <span>(1 of 4)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_24.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Service History <span>(2 of 4)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_25.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Service History <span>(3 of 4)</span></p>
                                </div>
                                <div className="col-xs-12 col-lg-6 col-xl-3">
                                    <Img lassName="img-fluid" fluid={data.polo_26.childImageSharp.fluid} alt="" />
                                    <p className="imagedetail">Service History <span>(4 of 4)</span></p>
                                </div>
                            </div>{/* row ends */}
                        </div>{/* container ends */}
                    </div>{/* container-fluid ends */}
                </>

        </Layout>
    )
}

export const data = graphql`
    {
        file(relativePath: { eq: "sara-banner-2.png" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_0: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_front-three-quarters-driver-side.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_1: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_front-head-on.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_2: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_front-three-quarters-passenger-side.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_3: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_side-view-passenger-side.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_4: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_logo.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_5: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_side-view-driver-side.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_6: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_rear-three-quarters-passenger-side.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_7: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_rear-head-on.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_8: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_rear-three-quarters-driver-side.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_9: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_wheel-driver-side-rear.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_10: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_wheel-driver-side-front.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_11: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_wheel-passenger-side-front.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_12: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_wheel-passenger-side-rear.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_13: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_open-boot.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_14: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_spare-wheel-well.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_15: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_front-interior-driver-side-16.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_16: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_front-interior-head-on.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_17: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_front-interior-passenger-side-17.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_18: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_rear-interior-driver-side-18.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_19: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_rear-interior-head-on-19.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_20: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_rear-interior-passenger-side-20.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_21: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_odometer.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_22: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_digital-display.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_23: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_service-history_01.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_24: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_service-history_02.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_25: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_service-history_03.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        polo_26: file(relativePath: { eq: "cars-photo-guide/polo/icashcars_service-history_04.jpeg" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
export default PhotoGuide
